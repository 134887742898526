import { getUserFromToken } from '@helpers/auth';
import {
	AccessToken,
	LoginUserAction,
	RefreshToken,
	UpdateUserAction,
	UpdateUserFromTokenAction,
	User,
	USER_LOGIN,
	USER_LOGOUT,
	USER_UPDATE,
	USER_UPDATE_FROM_TOKEN,
	UserActionTypes,
} from './types';
import { AppState, AppThunk } from '../types';
import { ThunkAction } from 'redux-thunk';
export const loginUser = (data?: {
	access_token: string;
	refresh_token: string;
}): AppThunk<UserActionTypes, Promise<UserActionTypes>> => async (dispatch) => {
	try {
		const userData = await getUserFromToken({
			token: data?.access_token,
			refreshToken: data?.refresh_token,
		});
		const { user, token, refreshToken } = userData;

		return dispatch({
			type: USER_LOGIN,
			payload: {
				user,
				token,
				refreshToken,
			},
		});
	} catch (error) {
		throw error;
	}
};
export const logoutUser = (): UserActionTypes => ({
	type: USER_LOGOUT,
});
export function updateUser(data?: {
	user?: User;
	token?: string;
	refreshToken?: string;
}): UpdateUserAction | AppThunk<UserActionTypes, Promise<UserActionTypes>> {
	if (!data?.user) {
		// update user with existing token/refresh token
		return async (dispatch) => {
			try {
				const { user, token, refreshToken } = await getUserFromToken({
					token: data?.token,
					refreshToken: data?.refreshToken,
				});

				return dispatch({
					type: USER_UPDATE_FROM_TOKEN,
					payload: { user, token, refreshToken },
				});
			} catch (error) {
				throw error;
			}
		};
	}

	return {
		type: USER_UPDATE,
		payload: data,
	};
}
